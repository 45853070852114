<template>
<!--begin: User bar -->
<div
    :class="{'show': showUserBar}"
    class="kt-header__topbar-item kt-header__topbar-item--user"
>
    <div
        class="kt-header__topbar-wrapper"
        @click.stop="setUserBar('toggle')"
    >
        <div class="kt-header__topbar-user">
            <span
                style="height: 32px; font-size: 1rem;"
                class="kt-badge kt-badge--username kt-badge--unified-brand kt-badge--lg kt-badge--rounded kt-badge--bold"
            >
                U
            </span>
        </div>

        <div
            :key="`userBar_${data.idx}`"
            class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
            :class="{'show': showUserBar}"
            :style="styleObject"
        >
            <!--begin: Head -->
            <div
                class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
                style="background-image: url(/images/bg-1.jpg)"
            >
                <div class="kt-user-card__avatar">
                    <span class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-brand kt-font-light">
                        U
                    </span>
                </div>
                <div class="kt-user-card__name">
                    Login
                </div>
                <!-- <div class="kt-user-card__badge">
                    <span
                        class="btn btn-label-brand btn-font-md"
                        style="color: white"
                    >Hey</span>
                </div> -->
            </div>
            <!--end: Head -->

            <!--begin: Navigation -->
            <div class="kt-notification">
                <router-link
                    :to="{name: 'AccountSessions'}"
                    class="kt-notification__item"
                >
                    <div class="kt-notification__item-icon">
                        <SVGIcon
                            name="account"
                            style="opacity: 0.8"
                        />
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            Google Single Sign On
                        </div>
                        <!-- <div class="kt-notification__item-time">
                            Login with google
                        </div> -->
                    </div>
                </router-link>
            </div>
            <!--end: Navigation -->
        </div>
    </div>
</div>

<!--end: User bar -->
</template>

<script>

import { mapState, mapMutations } from 'vuex';
import moment from 'moment';

export default {
    name: 'TheUserBar',
    data() {
        return {
            data: {
                idx: 0,
            },
        };
    },
    computed: {
        ...mapState({
            showUserBar: (state) => state.navigation.showUserBar,
            styleObject() {
                return {
                    position: 'absolute',
                    top: '60px',
                    right: '14px',
                    left: 'auto',
                };
            },
        }),
    },
    watch: {
        showUserBar() {
            this.data.idx += 1;
        },
    },
    methods: {
        ...mapMutations(['setUserBar']),
    },
};

</script>
<style scoped>

a.kt-notification__item {
    text-decoration: none;
}
div.kt-user-card__name {
    overflow: hidden;
}

div.kt-user-card__name span.text-muted {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    display: block;
}

button.sg-button {
    border-style: none;
}

.sg-sign-out {
    min-width: 80px;
}

.btn.sg-role-badge {
    line-height: 0.6em;
}

</style>
