var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kt-header__topbar kt-grid__item" },
    [_c("TheDashSearchBar"), _c("TheDashUserBar")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }