var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: _vm.key }, [
    _c(
      "div",
      {
        staticClass: "kt-header-mobile kt-header-mobile--fixed",
        attrs: { id: "kt_header_mobile" },
      },
      [
        _c("div", { staticClass: "kt-header-mobile__logo" }, [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.goHome()
                },
              },
            },
            [_vm._v(" The New York State Seal of Biliteracy Dashboard ")]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "kt-grid kt-grid--hor kt-grid--root" }, [
      _vm.ready
        ? _c(
            "div",
            {
              staticClass:
                "kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page",
            },
            [
              _c("TheDashboardAsideMenu", { key: `_${_vm.key}` }),
              _c(
                "div",
                {
                  staticClass:
                    "kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper",
                  staticStyle: { "overflow-x": "hidden" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "kt-header kt-grid__item kt-header--fixed",
                      attrs: { id: "kt_header" },
                    },
                    [_vm._m(0), _vm._m(1), _c("TheTopRightDashButtons")],
                    1
                  ),
                  _c(
                    "div",
                    {
                      class: `${_vm.deviceType}-device kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor pb-0`,
                      attrs: { id: "kt_content" },
                    },
                    [
                      _c("router-view"),
                      _c(
                        "div",
                        {
                          staticClass:
                            "kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop",
                          attrs: { id: "kt_footer" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "kt-container kt-container--fluid" },
                            [
                              _c(
                                "div",
                                { staticClass: "kt-footer__copyright" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(new Date().getFullYear()) +
                                      " ©  "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "kt-link",
                                      attrs: {
                                        href: "https://www.nysed.gov/world-languages/new-york-state-seal-biliteracy-nyssb",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Office of Bilingual Education and World Languages"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._m(2),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "kt-quick-panel", attrs: { id: "kt_quick_panel" } },
      [
        _c("TheQuickPanel", {
          scopedSlots: _vm._u([
            {
              key: "close",
              fn: function () {
                return [
                  _c(
                    "a",
                    {
                      staticClass: "kt-quick-panel__close",
                      attrs: { id: "kt_quick_panel_close_btn", href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.closeQuickPanel.apply(null, arguments)
                        },
                      },
                    },
                    [_c("i", { staticClass: "flaticon2-delete" })]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _vm.showQuickPanel
      ? _c("div", {
          staticClass: "kt-quick-panel-overlay",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.closeQuickPanel()
            },
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("button", { staticClass: "kt-header-menu-wrapper-close" }, [
      _c("i", { staticClass: "la la-close" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "kt-header-menu-wrapper",
        attrs: { id: "kt_header_menu_wrapper" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "kt-header-menu kt-header-menu-mobile kt-header-menu--layout-default",
            attrs: { id: "kt_header_menu" },
          },
          [
            _c(
              "h5",
              { staticClass: "text-light font-weight-bold mr-5 mt-4 pt-1" },
              [_vm._v(" The New York State Seal of Biliteracy ")]
            ),
            _c("ul", { staticClass: "kt-menu__nav" }, [
              _c("li", { staticClass: "kt-menu__item" }, [
                _c(
                  "a",
                  {
                    staticClass: "kt-menu__link",
                    attrs: {
                      href: "https://www.nysed.gov/world-languages/new-york-state-seal-biliteracy-nyssb",
                      target: "_blank",
                    },
                  },
                  [
                    _c("span", { staticClass: "kt-menu__link-text" }, [
                      _vm._v(" Resources "),
                    ]),
                  ]
                ),
              ]),
              _c("li", { staticClass: "kt-menu__item" }, [
                _c(
                  "a",
                  {
                    staticClass: "kt-menu__link",
                    attrs: {
                      href: "https://www.nysed.gov/sites/default/files/handbook-for-the-seal-of-biliteracy-2017-18_0.pdf",
                      target: "_blank",
                    },
                  },
                  [
                    _c("span", { staticClass: "kt-menu__link-text" }, [
                      _vm._v(" Handbook "),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-footer__menu" }, [
      _c(
        "a",
        {
          staticClass: "kt-footer__menu-link kt-link",
          attrs: {
            href: "https://www.nysed.gov/world-languages/new-york-state-seal-biliteracy-nyssbc",
            target: "_blank",
          },
        },
        [_vm._v("About")]
      ),
      _c(
        "a",
        {
          staticClass: "kt-footer__menu-link kt-link",
          attrs: {
            href: "https://www.nysed.gov/world-languages/new-york-state-seal-biliteracy-nyssbc",
            target: "_blank",
          },
        },
        [_vm._v("Team")]
      ),
      _c(
        "a",
        {
          staticClass: "kt-footer__menu-link kt-link",
          attrs: {
            href: "https://www.nysed.gov/world-languages/new-york-state-seal-biliteracy-nyssbc",
            target: "_blank",
          },
        },
        [_vm._v("Contact")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }