var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "li",
        {
          staticClass: "kt-menu__item",
          class: {
            "kt-menu__item--here kt-menu__item--active": _vm.active,
          },
        },
        [
          _c(
            "router-link",
            {
              staticClass: "kt-menu__link",
              attrs: { to: _vm.routerLinkParams },
            },
            [
              _vm.icon
                ? _c(
                    "span",
                    { staticClass: "kt-menu__link-icon" },
                    [_c("SVGIcon", { attrs: { name: _vm.icon } })],
                    1
                  )
                : _c(
                    "i",
                    {
                      staticClass:
                        "kt-menu__link-bullet kt-menu__link-bullet--dot",
                    },
                    [_c("span")]
                  ),
              _c("span", { staticClass: "kt-menu__link-text" }, [
                _c(
                  "div",
                  { staticClass: "menu-subtitle text-nowrap text-truncate" },
                  [_vm._v(" " + _vm._s(_vm.title) + " ")]
                ),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }