var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { key: `key_${_vm.title}_${_vm.key}` }, [
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _vm.canBeRestored
          ? _c("div", { staticClass: "kt-portlet" }, [
              _c("div", { staticClass: "kt-portlet__body pb-1 mb-1" }, [
                _c("div", { staticClass: "kt-section pb-0 mb-0" }, [
                  _c("div", { staticClass: "kt-section__content" }, [
                    _c(
                      "div",
                      { staticClass: "alert alert-solid-danger alert-bold" },
                      [
                        _vm._m(0),
                        _c("div", { staticClass: "alert-text" }, [
                          _vm._v(
                            " This course has been deleted. Would you like to restore it? "
                          ),
                        ]),
                        _c("div", { staticClass: "alert-close" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-success btn-bold btn-upper btn-sm",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.restoreCourse.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v(" Restore Course ")]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.course.canEditCourse
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "kt-portlet" }, [
                  _c("div", { staticClass: "kt-portlet__body pb-0" }, [
                    _c("div", { staticClass: "kt-section" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "kt-section__content kt-section__content--fit",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "kt-grid-nav kt-grid-nav--skin-light",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "kt-grid-nav__row row" },
                                _vm._l(_vm.items, function (item) {
                                  return _c(
                                    "router-link",
                                    {
                                      key: `course_nav_${item.icon}`,
                                      staticClass:
                                        "kt-grid-nav__item col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-2",
                                      staticStyle: {
                                        "padding-top": "10px !important",
                                        "padding-bottom": "10px !important",
                                      },
                                      attrs: { to: item.route },
                                      nativeOn: {
                                        mouseover: function ($event) {
                                          item.hovered = true
                                        },
                                        mouseleave: function ($event) {
                                          item.hovered = false
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "kt-grid-nav__icon w-100",
                                        },
                                        [
                                          _c("SVGIcon", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.hovered,
                                                expression: "item.hovered",
                                              },
                                            ],
                                            staticClass: "kt-svg-icon-custom",
                                            attrs: {
                                              name: item.icon,
                                              "hex-color": "#0f88ef",
                                            },
                                          }),
                                          _c("SVGIcon", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !item.hovered,
                                                expression: "!item.hovered",
                                              },
                                            ],
                                            staticClass: "kt-svg-icon-custom",
                                            attrs: {
                                              name: item.icon,
                                              "hex-color": "#c4cff9",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "kt-grid-nav__title w-100",
                                        },
                                        [_vm._v(" " + _vm._s(item.title) + " ")]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              class:
                _vm.$_userMixins_isGoogleDisabled || !_vm.course.canEditCourse
                  ? "col-12"
                  : "col-md-6",
            },
            [
              _c("div", { staticClass: "kt-portlet" }, [
                _c("div", { staticClass: "kt-portlet__head" }, [
                  _c("div", { staticClass: "kt-portlet__head-label" }, [
                    _c(
                      "span",
                      { staticClass: "kt-portlet__head-icon pr-0" },
                      [
                        _c("SVGIcon", {
                          staticClass: "mr-3",
                          attrs: { "hex-color": "#0f88ef", name: "info" },
                        }),
                      ],
                      1
                    ),
                    _vm._m(1),
                  ]),
                  _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                    _vm._v(" " + _vm._s(_vm.extCourseSectionId) + " "),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "kt-portlet__body" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "kt-widget kt-widget--user-profile-4 pb-5",
                      },
                      [
                        _c("div", { staticClass: "kt-widget__head" }, [
                          _c("div", { staticClass: "kt-widget__content" }, [
                            _c("div", { staticClass: "kt-widget__section" }, [
                              _vm.course.courseSectionTitle
                                ? _c(
                                    "span",
                                    { staticClass: "kt-widget__title pt-0" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.course.courseSectionTitle
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.course.courseSectionTitle
                                ? _c(
                                    "span",
                                    { staticClass: "kt-widget__title pt-0" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.course.name) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  staticClass: "kt-widget__subtitle pt-2 pb-4",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.course.extCourseSectionId) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "kt-widget__media" },
                            [
                              _vm.course
                                ? _c("CourseIcon", {
                                    staticClass: "kt-font-boldest",
                                    attrs: { course: _vm.course, size: "xl" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "kt-widget__content" }, [
                            _c(
                              "div",
                              { staticClass: "kt-widget__section" },
                              [
                                _vm.course.nextMeeting
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "kt-widget__subtitle pt-3",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (
                                                _vm.course.nextMeeting.day || ""
                                              ).substring(0, 3)
                                            ) +
                                            ", Period " +
                                            _vm._s(
                                              _vm.course.nextMeeting.period
                                            ) +
                                            ", " +
                                            _vm._s(
                                              _vm.course.nextMeeting.room
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.teacher
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass: "kt-widget__subtitle pt-2",
                                        attrs: {
                                          to: {
                                            name: "TeacherInfo",
                                            params: {
                                              schoolEmail:
                                                _vm.teacher.schoolEmail,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.teacher.lastName) +
                                            ", " +
                                            _vm._s(_vm.teacher.firstName) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c("CourseSectionsTeachers", {
                      attrs: { teacher: _vm.teacher, course: _vm.course },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.$_userMixins_isGoogleDisabled
                ? _c("TeacherCourseSettings", {
                    attrs: { teacher: _vm.teacher, course: _vm.course },
                  })
                : _vm._e(),
              _c("CourseSectionMeetings", { attrs: { course: _vm.course } }),
            ],
            1
          ),
          !_vm.$_userMixins_isGoogleDisabled
            ? _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("TeacherCourseSettings", {
                    attrs: { teacher: _vm.teacher, course: _vm.course },
                  }),
                  _c("GoogleCoursePortlet", { attrs: { course: _vm.course } }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "flaticon-warning" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h3", { staticClass: "kt-portlet__head-title" }, [
      _c("span", [_vm._v(" Course Info ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }