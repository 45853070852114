var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "kt-header__topbar-item kt-header__topbar-item--user",
      class: { show: _vm.showUserBar },
    },
    [
      _c(
        "div",
        {
          staticClass: "kt-header__topbar-wrapper",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.setUserBar("toggle")
            },
          },
        },
        [
          _vm._m(0),
          _c(
            "div",
            {
              key: `userBar_${_vm.data.idx}`,
              staticClass:
                "dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl",
              class: { show: _vm.showUserBar },
              style: _vm.styleObject,
            },
            [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "kt-notification" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "kt-notification__item",
                      attrs: { to: { name: "AccountSessions" } },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "kt-notification__item-icon" },
                        [
                          _c("SVGIcon", {
                            staticStyle: { opacity: "0.8" },
                            attrs: { name: "account" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "kt-notification__item-details" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "kt-notification__item-title kt-font-bold",
                            },
                            [_vm._v(" Google Single Sign On ")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-header__topbar-user" }, [
      _c(
        "span",
        {
          staticClass:
            "kt-badge kt-badge--username kt-badge--unified-brand kt-badge--lg kt-badge--rounded kt-badge--bold",
          staticStyle: { height: "32px", "font-size": "1rem" },
        },
        [_vm._v(" U ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x",
        staticStyle: { "background-image": "url(/images/bg-1.jpg)" },
      },
      [
        _c("div", { staticClass: "kt-user-card__avatar" }, [
          _c(
            "span",
            {
              staticClass:
                "kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-brand kt-font-light",
            },
            [_vm._v(" U ")]
          ),
        ]),
        _c("div", { staticClass: "kt-user-card__name" }, [_vm._v(" Login ")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }