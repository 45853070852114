var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop",
        class: { "kt-aside--on": _vm.show && _vm.showMobileMenu },
        attrs: { id: "kt_aside" },
        on: {
          mouseover: function ($event) {
            _vm.hovering = true
          },
          mouseout: function ($event) {
            _vm.hovering = false
          },
        },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid",
            staticStyle: { "margin-top": "50px" },
            attrs: { id: "kt_aside_menu_wrapper" },
          },
          [
            _c(
              "div",
              {
                staticClass: "kt-aside-menu",
                class: { "kt-aside--on": !_vm.show },
                attrs: { id: "kt_aside_menu py-5" },
              },
              [
                _c(
                  "div",
                  { staticStyle: { position: "absolute", bottom: "72px" } },
                  [_c("ClientSeal")],
                  1
                ),
                _c("h4", { staticClass: "px-4 pt-4 text-center" }, [
                  _vm._v(" Office of Bilingual Education and World Languages "),
                ]),
                _vm._m(0),
                _c("TheDashboardUserMenu"),
              ],
              1
            ),
          ]
        ),
      ]
    ),
    _vm.show && _vm.showMobileMenu
      ? _c("div", {
          staticClass: "kt-aside-overlay",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.setMobileMenu("toggle")
            },
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-center pt-4 pb-3" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-wide btn-primary btn-sm",
          attrs: {
            href: "https://www.nysed.gov/sites/default/files/programs/world-languages/nyssb-annual-report-2019-20-final.pdf",
            target: "_blank",
          },
        },
        [_vm._v(" Download the full report ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }