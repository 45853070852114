<template>
<li class="kt-menu__section mt-0">
    <h4 class="kt-menu__section-text">
        {{ title }}
    </h4>
    <i class="kt-menu__section-icon flaticon-more-v2" />
</li>
</template>

<script>
export default {
    name: 'AsideDashMenuItemHeader',
    props: {
        title: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
body.kt-aside-dark h4.kt-menu__section-text {
    color: #A1A8C3 !important;
}
</style>
