<template>
<div
    style="
        background: #fff;
        height: auto;
        z-index: 1;
        width: 100%;
        position: absolute;
    "
>
    <ul class="kt-menu__nav pt-0">
        <AsideDashMenuItemHeader title="Data Analytics" />

        <AsideDashMenuItem
            title="Home"
            route-name="BiliteracySealDashboardHome"
            icon="earth"
        />

        <AsideDashMenuItem
            title="Comparision Tool"
            route-name="BiliteracySealAnalysis"
            icon="chart-line"
        />

        <AnalysisMenu />
    </ul>

    <!-- Portfolio Viewer -->
    <ul
        v-for="(portfolio, idx) in portfolios"
        :key="`portfolioViewers_${idx}_${key}`"
        class="kt-menu__nav py-0"
    >
        <AsideDashMenuItemHeader
            v-if="idx == 0"
            title="Profiles"
        />

        <li class="kt-menu__item kt-menu__item menu-user-header">
            <router-link
                :id="`aside_profile_${idx}`"
                class="kt-menu__link pt-2 pb-2"
                :to="{
                    name: portfolio.routeName,
                    params: { id: portfolio.id },
                }"
            >
                <span
                    class="kt-menu__link-icon kt-menu__link-icon-close"
                    @click.stop.prevent="removeProfile(portfolio)"
                >
                    <i class="la la-close" />
                </span>
                <span class="kt-menu__link-icon kt-menu__link-icon-avatar">
                    <div class="kt-media kt-media--sm">
                        <a
                            style="width: 1.75rem;"
                            class="kt-media kt-media--lg kt-media--primary"
                        >
                            <span style="height: 1.75rem; font-size: .75rem;">
                                {{ portfolio.name.substring(0, 1) }}
                            </span>
                        </a>
                    </div>
                </span>

                <span class="kt-menu__link-text">
                    {{ portfolio.name }}
                </span>

                <i class="kt-menu__ver-arrow la la-angle-right" />
            </router-link>
        </li>
    </ul>
</div>
</template>

<script lang="ts">

import AnalysisMenu from './AnalysisMenu.vue';
import AsideDashMenuItem from './AsideDashMenuItem.vue';
import AsideDashMenuItemHeader from './AsideDashMenuItemHeader.vue';
import Types from '../../../store/Types';

export default {
    name: 'TheDashboardUserMenu',
    components: {
        AnalysisMenu,
        AsideDashMenuItem,
        AsideDashMenuItemHeader,
    },
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        portfolios() {
            return this.$store.state.biliteracySeal.profileHistory
                .map((profile) => {
                    let routeName = 'BiliteracySealSchoolProfile';
                    if (profile.type === 'districts') routeName = 'BiliteracySealDistrictProfile';
                    if (profile.type === 'regions') routeName = 'BiliteracySealRegionalProfile';
                    if (profile.type === 'big5') routeName = 'BiliteracySealBigFiveProfile';
                    return {
                        id: profile.id,
                        name: profile.name,
                        routeName,
                    };
                });
        },
    },
    methods: {
        removeProfile(profile) {
            this.$store.commit(Types.mutations.REMOVE_BILITERACY_PROFILE_HISTORY, profile);
        },
    },
};
</script>

<style scoped>
img.kt-media--sm--sidebar {
    width: 1.75rem !important;
    max-height: 2rem;
}

span.kt-menu__link-icon-close {
    display: none !important;
}
span.kt-menu__link-icon-close:hover {
    color: rgba(253, 57, 122, 0.8) !important
}

li.menu-user-header a.kt-menu__link:hover span.kt-menu__link-icon-close {
    display: flex !important;
    width: 35px !important;
}
span.kt-menu__link-icon-avatar {
    display: flex !important;
}
li.menu-user-header a.kt-menu__link:hover span.kt-menu__link-icon-avatar {
    display: none !important;
}

</style>
