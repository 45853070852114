<template>
<li
    class="kt-menu__item menu-user-header"
    :class="{
        'kt-menu__item--open': open,
        'kt-menu__item--dull-open': openAndDull
    }"
>
    <a
        href="#toggle-menu"
        class="kt-menu__link pt-2 pb-2"
        @click.stop.prevent="toggle"
    >
        <span class="kt-menu__link-icon">
            <SVGIcon name="list" />
        </span>

        <span class="kt-menu__link-text">
            Constituents
        </span>

        <i class="kt-menu__ver-arrow la la-angle-right" />
    </a>
    <b-collapse
        v-model="open"
        class="kt-menu__submenu"
    >
        <span class="kt-menu__arrow" />
        <ul class="kt-menu__subnav">
            <AsideDashMenuItem
                title="Big 5"
                route-name="BiliteracySealConstituents"
                :route-params="{ filter: 'big5' }"
            />
            <AsideDashMenuItem
                title="Regional"
                route-name="BiliteracySealConstituents"
                :route-params="{ filter: 'regions' }"
            />
            <AsideDashMenuItem
                title="Districts"
                route-name="BiliteracySealConstituents"
                :route-params="{ filter: 'districts' }"
            />
            <AsideDashMenuItem
                title="Schools"
                route-name="BiliteracySealConstituents"
                :route-params="{ filter: 'schools' }"
            />
        </ul>
    </b-collapse>
</li>
</template>

<script lang="ts">
import AsideDashMenuItem from './AsideDashMenuItem.vue';

export default {
    name: 'AnalysisMenu',
    components: {
        AsideDashMenuItem,
    },
    data() {
        return {
            open: false,
        };
    },
    computed: {
        openAndDull() {
            return Boolean(this.isActive && this.open);
        },
        isActive() {
            return this.$route.fullPath.indexOf('/biliteracy-seal/constituents') > -1;
        },
    },
    watch: {
        $route() {
            this.setOpenState();
        },
    },
    mounted() {
        this.setOpenState();
    },
    methods: {
        toggle() {
            this.open = !this.open;
            // setTimeout(() => {
            //     if (this.open) {
            //         // scroll to bottom of menu
            //         // document.getElementById('pscroll').parentNode.scrollTop = 999999;
            //     }
            // }, 300);
        },
        setOpenState() {
            const { isActive } = this;
            this.open = Boolean(isActive);
        },
    },
};
</script>

<style lang="scss">
.kt-menu__item--dull-open {
    .kt-menu__link-text {
        color: #3d4456 !important;
    }
    .kt-menu__link-icon svg g [fill] {
        fill: #c4cff9 !important;;
    }
}
</style>
