<template>
<div
    title="Search"
    :class="{ show: showSearchBar }"
    class="kt-header__topbar-item kt-header__topbar-item--search dropdown"
>
    <div
        class="kt-header__topbar-wrapper"
        data-toggle="dropdown"
        data-offset="10px, 0px"
        @click.stop.prevent="setSearchBar('toggle'); focus();"
    >
        <span class="kt-header__topbar-icon">
            <SVGIcon :name="'search'" />
        </span>
    </div>
    <div
        id="topSearch"
        :class="{ show: showSearchBar }"
        class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-lg"
    >
        <div
            class="kt-quick-search kt-quick-search--dropdown kt-quick-search--result-compact"
        >
            <form
                method="get"
                class="kt-quick-search__form"
                @submit.stop.prevent="selectPortfolio(0)"
            >
                <div
                    class="input-group"
                    style="background: transparent !important;"
                >
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="flaticon2-search-1" />
                        </span>
                    </div>
                    <input
                        id="main-search"
                        v-model="searchTerm"
                        type="text"
                        name="term"
                        style="background: transparent !important;"
                        class="form-control kt-quick-search__input"
                        autocomplete="off"
                        placeholder="Search constituents..."
                        @keyup.13="selectPortfolio(0)"
                        @click.stop.prevent="setSearchBar(true);"
                    >
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="la la-close kt-quick-search__close" />
                        </span>
                    </div>
                </div>
            </form>
        </div>
        <div
            v-if="portfolios && portfolios.length > 0"
            class="kt-widget4 p-3"
        >
            <div
                v-for="(portfolio, idx) in portfolios"
                :key="`${idx}_portfolio`"
                class="kt-widget4__item"
                @click.stop.prevent="selectPortfolio(idx)"
            >
                <div class="kt-widget4__pic kt-widget4__pic--pic">
                    <UserAvatar :avatar-user="portfolio">
                        <div class="kt-badge kt-badge--lg kt-badge--success">
                            {{ portfolio.lastName.substring(0,1) }}{{ portfolio.firstName.substring(0,1) }}
                        </div>
                    </UserAvatar>
                </div>
                <template v-if="portfolio.role == 'Student'">
                    <div class="kt-widget4__info">
                        <router-link
                            :to="{name: 'StudentInfo', params: { studentEnrollmentId: portfolio.studentEnrollmentId }}"
                            class="kt-widget4__username"
                        >
                            {{ portfolio.lastName }}, {{ portfolio.firstName }}
                        </router-link>
                        <div
                            class="text-muted text-truncate"
                            style="max-width: 150px"
                        >
                            <template v-if="portfolio.extStudentId.length == 9">
                                {{ portfolio.extStudentId.substring(0, 3) }}-{{
                                    portfolio.extStudentId.substring(3, 6)
                                }}-{{ portfolio.extStudentId.substring(6, 9) }}
                                {{ portfolio.homeRoom }}
                            </template>
                            <template v-else>
                                {{ portfolio.extStudentId }} {{ portfolio.homeRoom }}
                            </template>
                        </div>
                    </div>
                    <router-link
                        :to="{name: 'StudentInfo', params: { studentEnrollmentId: portfolio.studentEnrollmentId }}"
                        class="btn btn-sm btn-bold btn-label-warning"
                    >
                        <p class="kt-widget4__text m-0">
                            Student
                        </p>
                    </router-link>
                </template>
                <template v-if="portfolio.role == 'Teacher'">
                    <div class="kt-widget4__info">
                        <router-link
                            :to="{name: 'TeacherInfo', params: { schoolEmail: portfolio.schoolEmail }}"
                            class="kt-widget4__username"
                        >
                            {{ portfolio.lastName }}, {{ portfolio.firstName }}
                        </router-link>
                        <div
                            class="text-muted text-truncate"
                            style="max-width: 150px"
                        >
                            {{ portfolio.schoolEmail }}
                        </div>
                    </div>
                    <router-link
                        :to="{name: 'TeacherInfo', params: { schoolEmail: portfolio.schoolEmail }}"
                        class="btn btn-sm btn-bold btn-label-brand"
                    >
                        <p class="kt-widget4__text m-0">
                            Teacher
                        </p>
                    </router-link>
                </template>
                <template v-if="portfolio.role == 'Guardian'">
                    <div class="kt-widget4__info">
                        <router-link
                            :to="{name: 'StudentGuardians', params: { studentEnrollmentId: portfolio.studentEnrollmentId }}"
                            class="kt-widget4__username"
                        >
                            {{ portfolio.lastName }}, {{ portfolio.firstName }}
                        </router-link>
                        <div
                            class="text-muted text-truncate"
                            style="max-width: 150px"
                        >
                            {{ `${portfolio.relationToStudent} of ${portfolio.lastName}` }}
                        </div>
                    </div>
                    <router-link
                        :to="{name: 'StudentGuardians', params: { studentEnrollmentId: portfolio.studentEnrollmentId }}"
                        class="btn btn-sm btn-bold btn-label-success"
                    >
                        <p class="kt-widget4__text m-0">
                            Guardian
                        </p>
                    </router-link>
                </template>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import UserAvatar from '../../../components/UserAvatar.vue';

export default {
    name: 'TheDashSearchBar',
    components: {
        UserAvatar,
    },
    data() {
        return {};
    },
    computed: {
        ...mapState({
            showSearchBar: (state) => state.navigation.showSearchBar,
            searchTerm: (state) => state.search.searchTerm,
            teachers: (state) => state.database.teachers,
            students: (state) => state.database.students,
            studentGuardians: (state) => state.database.studentGuardians,
        }),
        searchTerm: {
            get() {
                return this.$store.state.search.searchTerm;
            },
            set(value) {
                this.setSearchTerm(value);
            },
        },
        portfolios() {
            const searchTerm = ('' || this.$store.state.search.searchTerm).toLowerCase();
            const studentGuardians = this.studentGuardians.map((guardian) => ({
                ...guardian,
                firstName: guardian.guardianFirstName,
                lastName: guardian.guardianLastName,
            }));
            const portfolios = [...this.teachers, ...this.students, ...studentGuardians]
                .map((x) => {
                    const port = x;
                    port.role = this.getRole(x);
                    return port;
                });
            const output = portfolios.filter((t) => {
                const search = `${t.firstName} ${t.lastName} ${t.extStudentId || ''} ${t.googleEmail || ''} ${t.schoolEmail || ''}`.toLowerCase();
                return (search.indexOf(searchTerm.toLowerCase()) > -1);
            });

            return output.slice(0, 3);
        },
    },
    methods: {
        ...mapMutations([
            'setSearchBar',
            'setSearchTerm',
        ]),
        selectPortfolio(idx) {
            if (this.portfolios.length >= idx) {
                const portfolio = this.portfolios[idx];
                const { schoolEmail, studentEnrollmentId } = portfolio;
                switch (portfolio.role) {
                case 'Student':
                    this.$router.push({ name: 'StudentInfo', params: { studentEnrollmentId } });
                    break;
                case 'Teacher':
                    this.$router.push({ name: 'TeacherInfo', params: { schoolEmail } });
                    break;
                case 'Guardian':
                    this.$router.push({ name: 'StudentGuardians', params: { studentEnrollmentId } });
                    break;
                default:
                    break;
                }
            }
            this.setSearchBar(false);
        },
        focus() {
            this.searchTerm = '';
            setTimeout(() => {
                const v = this;
                const input = v.$el.querySelector('#main-search');
                input.focus();
            }, 150);
        },
        getRole(portfolio) {
            const { studentEnrollmentId, studentGuardianId } = portfolio;
            if (studentGuardianId) {
                return 'Guardian';
            }
            return studentEnrollmentId ? 'Student' : 'Teacher';
        },
    },
};
</script>

<style scoped>
#topSearch.show {
    position: absolute;
    transform: translate3d(-268px, 60px, 0px);
    top: 18px;
    left: 0px;
    will-change: transform;
}
@media (max-width: 768px) {
    #topSearch.show {
        top: -12px;
        left: -12px;
    }
}
</style>
