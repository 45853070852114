import { render, staticRenderFns } from "./AnalysisMenu.vue?vue&type=template&id=b121d988"
import script from "./AnalysisMenu.vue?vue&type=script&lang=ts"
export * from "./AnalysisMenu.vue?vue&type=script&lang=ts"
import style0 from "./AnalysisMenu.vue?vue&type=style&index=0&id=b121d988&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/apps/SyncGrades.Web/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b121d988')) {
      api.createRecord('b121d988', component.options)
    } else {
      api.reload('b121d988', component.options)
    }
    module.hot.accept("./AnalysisMenu.vue?vue&type=template&id=b121d988", function () {
      api.rerender('b121d988', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/DistrictDashboard/components/AnalysisMenu.vue"
export default component.exports