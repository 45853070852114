var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        background: "#fff",
        height: "auto",
        "z-index": "1",
        width: "100%",
        position: "absolute",
      },
    },
    [
      _c(
        "ul",
        { staticClass: "kt-menu__nav pt-0" },
        [
          _c("AsideDashMenuItemHeader", { attrs: { title: "Data Analytics" } }),
          _c("AsideDashMenuItem", {
            attrs: {
              title: "Home",
              "route-name": "BiliteracySealDashboardHome",
              icon: "earth",
            },
          }),
          _c("AsideDashMenuItem", {
            attrs: {
              title: "Comparision Tool",
              "route-name": "BiliteracySealAnalysis",
              icon: "chart-line",
            },
          }),
          _c("AnalysisMenu"),
        ],
        1
      ),
      _vm._l(_vm.portfolios, function (portfolio, idx) {
        return _c(
          "ul",
          {
            key: `portfolioViewers_${idx}_${_vm.key}`,
            staticClass: "kt-menu__nav py-0",
          },
          [
            idx == 0
              ? _c("AsideDashMenuItemHeader", { attrs: { title: "Profiles" } })
              : _vm._e(),
            _c(
              "li",
              { staticClass: "kt-menu__item kt-menu__item menu-user-header" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "kt-menu__link pt-2 pb-2",
                    attrs: {
                      id: `aside_profile_${idx}`,
                      to: {
                        name: portfolio.routeName,
                        params: { id: portfolio.id },
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "kt-menu__link-icon kt-menu__link-icon-close",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.removeProfile(portfolio)
                          },
                        },
                      },
                      [_c("i", { staticClass: "la la-close" })]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "kt-menu__link-icon kt-menu__link-icon-avatar",
                      },
                      [
                        _c("div", { staticClass: "kt-media kt-media--sm" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "kt-media kt-media--lg kt-media--primary",
                              staticStyle: { width: "1.75rem" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    height: "1.75rem",
                                    "font-size": ".75rem",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(portfolio.name.substring(0, 1)) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c("span", { staticClass: "kt-menu__link-text" }, [
                      _vm._v(" " + _vm._s(portfolio.name) + " "),
                    ]),
                    _c("i", {
                      staticClass: "kt-menu__ver-arrow la la-angle-right",
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }