<template>
<div :key="key">
    <!-- Mobile Header -->
    <div
        id="kt_header_mobile"
        class="kt-header-mobile kt-header-mobile--fixed"
    >
        <!-- <div class="kt-header-mobile__toolbar">
            <button
                id="kt_aside_mobile_toggler"
                class="kt-header-mobile__toggler kt-header-mobile__toggler--left"
                @click="setMobileMenu('toggle')"
            >
                <span />
            </button>
        </div> -->

        <div class="kt-header-mobile__logo">
            <a
                href="#"
                @click.stop.prevent="goHome()"
            >
                The New York State Seal of Biliteracy Dashboard
            </a>
        </div>
        <!-- <div class="kt-header-mobile__toolbar">
            <button
                id="kt_header_mobile_topbar_toggler"
                class="kt-header-mobile__topbar-toggler"
                @click="setMobileTopBar('toggle')"
            >
                <i class="flaticon-more" />
            </button>
        </div> -->
    </div>

    <div class="kt-grid kt-grid--hor kt-grid--root">
        <div v-if="ready" class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <TheDashboardAsideMenu :key="`_${key}`" />

            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" style="overflow-x: hidden;">
                <!-- Top Navigation -->
                <div
                    id="kt_header"
                    class="kt-header kt-grid__item kt-header--fixed"
                >
                    <button class="kt-header-menu-wrapper-close">
                        <i class="la la-close" />
                    </button>

                    <!-- <div class="kt-header-menu-wrapper w-100">
                        <div class="kt-header__topbar-wrapper course-container w-100 pl-4 pr-4" />
                    </div> -->

                    <div id="kt_header_menu_wrapper" class="kt-header-menu-wrapper">
                        <div id="kt_header_menu" class="kt-header-menu kt-header-menu-mobile kt-header-menu--layout-default">
                            <h5 class="text-light font-weight-bold mr-5 mt-4 pt-1">
                                The New York State Seal of Biliteracy
                            </h5>
                            <ul class="kt-menu__nav">
                                <li class="kt-menu__item">
                                    <a
                                        href="https://www.nysed.gov/world-languages/new-york-state-seal-biliteracy-nyssb"
                                        target="_blank"
                                        class="kt-menu__link"
                                    >
                                        <span class="kt-menu__link-text">
                                            Resources
                                        </span>
                                    </a>
                                </li>
                                <li class="kt-menu__item">
                                    <a
                                        href="https://www.nysed.gov/sites/default/files/handbook-for-the-seal-of-biliteracy-2017-18_0.pdf"
                                        target="_blank"
                                        class="kt-menu__link"
                                    >
                                        <span class="kt-menu__link-text">
                                            Handbook
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <TheTopRightDashButtons />
                </div>

                <!-- Actual Router View Content -->
                <div id="kt_content" :class="`${deviceType}-device kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor pb-0`">
                    <router-view />
                    <div id="kt_footer" class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop">
                        <div class="kt-container  kt-container--fluid ">
                            <div class="kt-footer__copyright">
                                {{ new Date().getFullYear() }}&nbsp;©&nbsp;
                                <a
                                    href="https://www.nysed.gov/world-languages/new-york-state-seal-biliteracy-nyssb"
                                    target="_blank"
                                    class="kt-link"
                                >Office of Bilingual Education and World Languages</a>
                            </div>
                            <div class="kt-footer__menu">
                                <a
                                    href="https://www.nysed.gov/world-languages/new-york-state-seal-biliteracy-nyssbc"
                                    target="_blank"
                                    class="kt-footer__menu-link kt-link"
                                >About</a>
                                <a
                                    href="https://www.nysed.gov/world-languages/new-york-state-seal-biliteracy-nyssbc"
                                    target="_blank"
                                    class="kt-footer__menu-link kt-link"
                                >Team</a>
                                <a
                                    href="https://www.nysed.gov/world-languages/new-york-state-seal-biliteracy-nyssbc"
                                    target="_blank"
                                    class="kt-footer__menu-link kt-link"
                                >Contact</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        id="kt_quick_panel"
        class="kt-quick-panel"
    >
        <TheQuickPanel>
            <template #close>
                <a
                    id="kt_quick_panel_close_btn"
                    href="#"
                    class="kt-quick-panel__close"
                    @click.stop.prevent="closeQuickPanel"
                >
                    <i class="flaticon2-delete" />
                </a>
            </template>
        </TheQuickPanel>
    </div>
    <div
        v-if="showQuickPanel"
        class="kt-quick-panel-overlay"
        @click.stop.prevent="closeQuickPanel()"
    />
</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import TheDashboardAsideMenu from './components/TheDashboardAsideMenu.vue';
import TheTopRightDashButtons from './components/TheTopRightDashButtons.vue';
import TheQuickPanel from '../TheRouterLayout/components/TheQuickPanel.vue';
import Types from '../../store/Types';
import * as network from '../../network';
import router from '../../router/index';

export default {
    name: 'TheDistrictDashboardLayout',
    components: {
        TheQuickPanel,
        TheTopRightDashButtons,
        TheDashboardAsideMenu,
    },
    data() {
        return {
            key: 0,
            ready: false,
        };
    },
    computed: {
        showQuickPanel() {
            return this.$store.state.quickPanel.open;
        },
        deviceType: {
            get() {
                return this.$store.state.deviceType;
            },
            set(deviceType) {
                this.$store.commit(Types.mutations.SET_RESPONSIVE_DEVICE_TYPE, deviceType);
            },
        },
        ...mapGetters({
            showSubHeader: Types.getters.IS_SUBHEADER_ENABLED,
        }),
    },
    watch: {
        $route() {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 100);
        },
        showQuickPanel(val) {
            if (val) {
                document.body.classList.add('kt-quick-panel--on');
                document.getElementById('kt_quick_panel').classList.add('kt-quick-panel--on');
            } else {
                document.body.classList.remove('kt-quick-panel--on');
                document.getElementById('kt_quick_panel').classList.remove('kt-quick-panel--on');
            }
        },
    },
    mounted() {
        window.addEventListener('beforeunload', this.beforeWindowUnload);
        window.addEventListener('resize', this.storeWindowSize);

        this.storeWindowSize();
        this.enableAsideMenu(false);

        this.configureSubHeader();

        const { deviceType, setMobileMenu } = this;
        router.beforeEach((to, from, next) => {
            if (deviceType == 'mobile' || deviceType == 'tablet') setMobileMenu(false);
            next();
        });

        // document.body.classList.add('kt-footer--fixed');
        document.body.classList.remove('kt-aside-light');
        document.body.classList.remove('kt-aside--fixed');
        document.body.classList.remove('kt-aside--enabled');

        // add static demo data
        this.$store.commit(Types.mutations.SET_BILTERACY_SEAL_DEMO_DATA);

        const { commit } = this.$store;
        network.session.refreshSession({}, (err, user) => {
            if (err) return commit(Types.mutations.HANDLE_ERROR, 'Session expired');
            // commit(Types.mutations.SET_USER_PROFILE, user);
            this.ready = true;
            this.key += 1;
        });
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
        window.removeEventListener('resize', this.storeWindowSize);
    },
    methods: {
        ...mapMutations([
            'closeAllNavigationPopovers',
            'setMobileMenu',
            'setMobileTopBar',
        ]),
        responsiveDeviceCategory(windowWidth) {
            if (windowWidth < 576) return 'mobile';
            if (windowWidth < 1026) return 'tablet';
            if (windowWidth < 1280) return 'laptop';
            return 'desktop';
        },
        closeQuickPanel() {
            this.$store.commit(Types.mutations.CLOSE_QUICK_PANEL);
            this.$router.push({
                name: this.$route.name,
                params: this.$route.params,
                query: null,
            });
        },
        storeWindowSize() {
            const v = this;
            const { $store, responsiveDeviceCategory, configureSubHeader } = this;
            clearTimeout(this.debounceResize);
            this.debounceResize = setTimeout(() => {
                const windowWidth = document.documentElement.clientWidth;
                const windowHeight = document.documentElement.clientHeight;
                $store.commit(Types.mutations.SET_WINDOW_SIZE, { windowWidth, windowHeight });
                v.deviceType = responsiveDeviceCategory(windowWidth);
                configureSubHeader();
            }, 100);
        },
        configureSubHeader() {
            if (this.showSubHeader) {
                document.body.classList.add('kt-subheader--enabled', 'kt-subheader--fixed', 'kt-subheader--solid');
            } else {
                document.body.classList.remove('kt-subheader--enabled', 'kt-subheader--fixed', 'kt-subheader--solid');
            }
        },
        enableAsideMenu(val) {
            if (val) {
                document.body.classList.add('kt-aside--minimize');
            } else {
                document.body.classList.remove('kt-aside--minimize');
                document.body.classList.remove('kt-aside--minimize-hover');
            }
        },
    },
};
</script>

<style lang="css" src="socicon/css/socicon.css"></style>
<style lang="css" src="@fortawesome/fontawesome-free/css/all.min.css"></style>
<style lang="css" src="../../assets/plugins/line-awesome/css/line-awesome.css"></style>
<style lang="css" src="../../assets/plugins/flaticon/flaticon.css"></style>
<style lang="css" src="../../assets/plugins/flaticon2/flaticon.css"></style>

<style lang="scss" src="../../assets/sass/style.vue.scss"></style>
<style lang="scss" src="../../assets/sass/global/integration/frameworks/vue/_skins.scss"></style>
<style lang="scss" src="../../assets/sass/global/layout/header/skins/menu/dark.scss"></style>
<style lang="scss" src="../../assets/sass/global/layout/aside/skins/light.scss"></style>
<style lang="scss" src="../../assets/sass/global/layout/brand/skins/dark.scss"></style>
<style lang="scss" src="../../assets/sass/global/layout/header/skins/base/dark.scss"></style>

<style>
#kt_subheader {
    left: 265px !important;
}
</style>
