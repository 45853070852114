import { render, staticRenderFns } from "./TheDistrictDashboardLayout.vue?vue&type=template&id=72fc3db2"
import script from "./TheDistrictDashboardLayout.vue?vue&type=script&lang=js"
export * from "./TheDistrictDashboardLayout.vue?vue&type=script&lang=js"
import style0 from "socicon/css/socicon.css?vue&type=style&index=0&lang=css&external"
import style1 from "@fortawesome/fontawesome-free/css/all.min.css?vue&type=style&index=1&lang=css&external"
import style2 from "../../assets/plugins/line-awesome/css/line-awesome.css?vue&type=style&index=2&lang=css&external"
import style3 from "../../assets/plugins/flaticon/flaticon.css?vue&type=style&index=3&lang=css&external"
import style4 from "../../assets/plugins/flaticon2/flaticon.css?vue&type=style&index=4&lang=css&external"
import style5 from "../../assets/sass/style.vue.scss?vue&type=style&index=5&lang=scss&external"
import style6 from "../../assets/sass/global/integration/frameworks/vue/_skins.scss?vue&type=style&index=6&lang=scss&external"
import style7 from "../../assets/sass/global/layout/header/skins/menu/dark.scss?vue&type=style&index=7&lang=scss&external"
import style8 from "../../assets/sass/global/layout/aside/skins/light.scss?vue&type=style&index=8&lang=scss&external"
import style9 from "../../assets/sass/global/layout/brand/skins/dark.scss?vue&type=style&index=9&lang=scss&external"
import style10 from "../../assets/sass/global/layout/header/skins/base/dark.scss?vue&type=style&index=10&lang=scss&external"
import style11 from "./TheDistrictDashboardLayout.vue?vue&type=style&index=11&id=72fc3db2&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/apps/SyncGrades.Web/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('72fc3db2')) {
      api.createRecord('72fc3db2', component.options)
    } else {
      api.reload('72fc3db2', component.options)
    }
    module.hot.accept("./TheDistrictDashboardLayout.vue?vue&type=template&id=72fc3db2", function () {
      api.rerender('72fc3db2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/DistrictDashboard/TheDistrictDashboardLayout.vue"
export default component.exports