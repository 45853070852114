<template>
<li
    v-if="isVisible"
    class="kt-menu__item"
    :class="{
        'kt-menu__item--here kt-menu__item--active': active,
    }"
>
    <router-link
        :to="routerLinkParams"
        class="kt-menu__link"
    >
        <span
            v-if="icon"
            class="kt-menu__link-icon"
        >
            <SVGIcon :name="icon" />
        </span>

        <i
            v-else
            class="kt-menu__link-bullet kt-menu__link-bullet--dot"
        >
            <span />
        </i>

        <span class="kt-menu__link-text">
            <div class="menu-subtitle text-nowrap text-truncate">
                {{ title }}
            </div>
        </span>
    </router-link>
</li>
</template>

<script>

export default {
    name: 'AsideDashMenuItem',
    props: {
        title: {
            type: String,
            required: true,
        },
        routeName: {
            type: String,
            required: true,
        },
        routeParams: {
            type: Object,
            required: false,
            default: null,
        },
        icon: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        routerLinkParams() {
            if (!this.routeParams) {
                return { name: this.routeName };
            }
            return {
                name: this.routeName,
                params: this.routeParams,
            };
        },
        active() {
            return Boolean(this.$route.name == this.name);
        },
        isVisible() {
            const { routeName } = this;
            if (this.isLocalRoute(routeName)) return this.isLocalDev;
            if (this.isBetaRoute(routeName)) return this.isBetaUser;
            return true;
        },
    },
    methods: {

    },
};
</script>

<style scoped>
    .menu-subtitle {
        width: 90%;
    }
</style>
