<template>
<div class="kt-header__topbar kt-grid__item">
    <TheDashSearchBar />
    <TheDashUserBar />
</div>
</template>

<script lang="ts">

import TheDashSearchBar from './TheDashSearchBar.vue';
import TheDashUserBar from './TheDashUserBar.vue';

export default {
    name: 'TheTopRightDashButtons',
    components: {
        TheDashSearchBar,
        TheDashUserBar,
    },
};
</script>
